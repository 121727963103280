import { h } from 'preact';
import { useState, useEffect } from "preact/hooks";
import CONSTANTS from '../../../lib/constants';
import { Link } from 'preact-router';
import { AppStore } from '../../../lib/store';
import http from 'fetch-bb';

// export default class UserVerification extends Component {
const UserVerification = (props) => {

  let [ token, setToken ] = useState(props.matches.verificationToken);
  let [ isLinkValid, setIsLinkValid ] = useState(false);
  function verifyUserToken() {
    http.put(`${CONSTANTS.API_URL}/api/admin/user/verify?verificationToken=${token}`)
      .then(() => {
        // this.setState({isLinkValid: true});
        setIsLinkValid(true);
        AppStore.set('tokenForGettingUser', token);
      })
      .catch(() => {
        // this.setState({isLinkInvalid: true});
        setIsLinkValid(true);
      });
  }
  // componentWillMount() {
  //   this.state = {
  //     token: ''
  //   };
  //   this.setState({token: this.props.matches.verificationToken});
  // }

  // componentDidMount() {
  //   this.verifyUserToken();
  // }

  useEffect(() => {
    verifyUserToken();
  }, []);

  // render({}, { isLinkValid, isLinkInvalid }) {
  return (
    <section class="row row-center has-text-center auth-section">
      <div class="column column-40 auth-center">
        <div class="row box" style="height:100px;align-items:center">
          <div class="column">
            {
              isLinkInvalid && (<label>This link is expired</label>)
            }
            {
              isLinkValid && (
                <label>Thank You for verifying. You can now proceed to <Link class="hyperlink" href='/admin/setpassword'> Set Password</Link></label>
              )
            }
          </div>
        </div>
      </div>
    </section>
  );
  // }
};

export default UserVerification;
