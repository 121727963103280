import { h } from "preact";
import { useState, useEffect } from "preact/hooks";

// export default class Pagination extends Component {
const Pagination = (props) => {

  let [ pageNoText, setPageNoText ] = useState(0);

  function onPreviosPageClick() {
    props.onChangePageClick(Number(props.currentPageNo) - 1);
  }

  function onNextPageClick() {
    props.onChangePageClick(Number(props.currentPageNo) + 1);
  }

  function goToPage(e) {
    e.preventDefault();
    //T1473 : added textbox to jump on page provided by page no in textbox
    // if page no sent empty  and if page no provided is greater than total pages then be on current page
    // Developer: Samruddhi
    // Date: 11/7/2018
    if (e.target.pageNoText.value !== '' && Math.ceil(props.count/10) >= e.target.pageNoText.value) {
      // this.setState({pageNoText:Number(e.target.pageNoText.value)});
      setPageNoText(Number(e.target.pageNoText.value));
      props.onChangePageClick(e.target.pageNoText.value);
    }
    else
    {
      props.onChangePageClick(Math.ceil(props.count/10));
    }
  }
  // componentWillMount(){
  //   this.state = {
  //     pageNoText:0
  //   };
  // }

  //render(props, {}) {
  if (!Number(props.count)) props.count = 1;

  return (
    <form onSubmit={() => goToPage()}>
      <div>
        <span>
          <span>{props.currentPageNo} / {Math.ceil(props.count/10)}</span>
          <button type="button" onClick={() => onPreviosPageClick()} disabled={(props.currentPageNo === 1)
               || (pageNoText === 1)}>{'<'}</button>
          <input type="number" class="no-spinners" name="pageNoText" style="width:50px; margin: 0 3px 0 3px;"
            min="1" maxlength="5" value={props.currentPageNo}/>
          <button type="button" onClick={() => onNextPageClick()} disabled={(props.currentPageNo === (Math.ceil(props.count/10)))
               || (pageNoText === (Math.ceil(props.count/10)))}>{'>'}</button>
        </span>
      </div>
    </form>
  );
  //}
};

export default Pagination;
