import { h, Component } from 'preact';
import { Router } from 'preact-router';
import { Match } from 'preact-router/src/match';

// import Header from './header';
// import Header1 from './header1';
import SideMenu from './sideMenu';
import Login from '../routes/login';
import Admin from '../routes/admin';
import NotFound from '../components/error_pages/not_found';
import ForgotPassword from '../routes/forgotpassword';
import ResetPassword from '../routes/resetpassword';
import Profile from '../routes/profile';
import VerifyOldPassword from '../routes/verifyOldPassword';
import ChangePassword from '../routes/changePassword';
import UserVerification from '../routes/userVerification';
import SetPassword from '../routes/setpassword';
import Footer from './footer';
import AdminForgotPassword from '../routes/admin/forgotpassword';
import AdminVerifyOldPassword from '../routes/admin/verifyOldPassword';
import AdminChangePassword from '../routes/admin/changePassword';
import AdminSetPassword from '../routes/admin/setpassword';
import AdminUserVerification from '../routes/admin/userVerification';
import AdminResetPassword from '../routes/admin/resetpassword';
import AdminHeader from '../components/adminheader';

import Home from '../routes/home';
import AdminPanel from '../routes/adminPanel';
import Branches from '../routes/branch';
import Users from '../routes/users';
import Role from '../routes/roles';
import Client from '../routes/clients';
import ClientDetails from '../routes/clientDetails';
import Consignments from '../routes/consignments';
import Vendors from '../routes/vendors';
import VendorDetails from '../routes/vendorDetails';
import ConsignmentDetails from '../routes/consignmentDetails';
import Rates from '../routes/rates';
import Companies from '../routes/admin/companies';
import CompanyDetails from '../routes/companyDetails';
import BillGeneration from '../routes/billGeneration';
import Bills from '../routes/bills';
import LorryReceipt from '../components/lorryReceipt';
import Bill from '../components/bill';
/*
Modified By: Prasannadatta kawadkar
Modified At: 01 Oct 2022
Modification: imported kanban
*/
import KanbanRoute from '../routes/kanbanRoute';
/*
  Modified by: Prasannadatta Kawadkar
  Modified on: 11 Oct 2022
  Modification: imported Manifest to app.js for rendering
*/
import Manifest from '../routes/manifest';
/*
  Modified by: Prasannadatta Kawadkar
  Modified on: 20 Oct 2022
  Modification: imported CashSheet to app.js for rendering
*/
import CashSheet from '../routes/cashSheet';

/*
  Modified by: Prasannadatta Kawadkar
  Modified on: 02 Jan 2023
  Modification: imported Report to app.js for rendering
*/
import Report from '../routes/report';
/*
  Modified by: Komal
  Modified on: 18 March 2023
  Modification: imported Tracking to app.js for rendering
*/
import Tracking from '../routes/tracking';

export default class App extends Component {
  componentDidMount() {

  }
  checkAdminConditions(path) {
    return path !== '/admin/forgotpassword' && path !== '/admin/resetpassword' && path !== '/admin/verify' && path !== '/admin/setpassword' && path !== '/'
      && path !== '/admin/login';
  }
  render() {
    return (
      <div>
        <div id="loader-bg">
          <div id="loader" />
        </div>
        <div id="app" class="container">
          <Match path="/">
            {
              ({path}) => {
                if ((/\/admin\/([a-zA-Z])*/.test(path) || path === '/admin') && path !== '/admin/login' && this.checkAdminConditions(path)) {
                  return (<AdminHeader/>);
                }
                if (path !== '/setup' && !/\/forgotpassword/.test(path) &&
                 path !== '/resetpassword' && path !== '/verify' && path !== '/setpassword' && path !== '/notFound' && this.checkAdminConditions(path)) {
                  return (<SideMenu/>);
                }
                if (path !== '/setup' && !/\/forgotpassword/.test(path) &&
                 path !== '/resetpassword' && path !== '/verify' && path !== '/setpassword' && path !== '/notFound' && this.checkAdminConditions(path)) {
                  return (<Footer/>);
                }
              }
            }
          </Match>
          <div style="transition: margin-left .5s;">
            <Router>
              <NotFound path ='/notFound' type="404" default/>
              <Login path="/"/>
              <Home path="/home"/>
              <AdminPanel path="/adminPanel" />
              <Admin path="/admin/:submodule?/:id?"/>
              <UserVerification path="/verify"/>
              <ForgotPassword path="/forgotpassword"/>
              <ResetPassword path="/resetpassword"/>
              <Profile path="/profile" />
              <VerifyOldPassword path="/verifyOldPassword" />
              <ChangePassword path="/changePassword" />
              <SetPassword path="/setpassword" />
              <AdminUserVerification path="/admin/verify"/>
              <AdminVerifyOldPassword path="/admin/verifyOldPassword" />
              <AdminChangePassword path="/admin/changePassword" />
              <AdminForgotPassword path="/admin/forgotpassword"/>
              <AdminSetPassword path="/admin/setpassword" />
              <AdminResetPassword path="/admin/resetpassword" />
              <Branches path="/branches" />
              <Users path="/users" />
              <Role path="/roles" />
              <Client path="/clients" />
              <ClientDetails path="/client/:clientID" />
              <Consignments path="/consignments" />
              <Vendors path="/vendors" />
              <VendorDetails path="/vendor/:vendorID" />
              <ConsignmentDetails path="/consignment/:consignmentID" />
              <Rates path="/rates" />
              <Companies path="/admin/companies" />
              <CompanyDetails path="/company/:companyID" />
              <BillGeneration path="/billGeneration" />
              <Bills path="/bills" />
              <KanbanRoute path="/KanbanRoute" />
              <Manifest path="/manifest" />
              <CashSheet path="/cashSheet" />
              <Report path="/report" />
              <Tracking path="/tracking" />
            </Router>
          </div>
        </div>
      </div>
    );
  }
}
