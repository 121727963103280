import { h } from 'preact';
import { useState, useEffect } from "preact/hooks";
import CONSTANTS from '../../../lib/constants';
import { Toast } from '../../../lib/toastr';
// import { route } from 'preact-router';
import http from 'fetch-bb';

//export default class ForgotPassword extends Component {
const ForgotPassword = (props) => {

  let [ isSendButtonDisabled, setIsSendButtonDisabled ] = useState(false);
  let [ email, setEmail ] = useState('');
  let [ errorMessage, setErrorMessage ] = useState('');
  let [ successMessage, setSuccessMessage ] = useState('');
  let [ isResponseReceived, setIsResponseReceived ] = useState(false);

  function send(e) {
    e.preventDefault();
    // this.setState({
    //   email: e.target.email.value,
    //   isResponseReceived:true,
    //   errorMessage: '',
    //   successMessage: ''
    // });
    setEmail(e.target.email.value);
    setIsResponseReceived(true);
    setErrorMessage('');
    setSuccessMessage('');
    http.post(`${CONSTANTS.API_URL}/api/admin/forgotpassword`, {
      email: e.target.email.value
    })
      .then(() => {
        new Toast('Please check your Inbox for further instructions', Toast.TYPE_DONE, Toast.TIME_LONG);
        // this.setState({successMessage: 'Please check your Inbox for further instructions'});
        // this.setState({errorMessage: ''});
        // this.state.isSendButtonDisabled = true;
        // this.setState({
        //   isResponseReceived:false
        // });
        setSuccessMessage('Please check your Inbox for further instructions');
        setErrorMessage('');
        setIsSendButtonDisabled(true);
        setIsResponseReceived(false);
      })
      .catch(() => {
        // this.setState({errorMessage: 'Registered user not found'});
        // this.setState({successMessage: ''});
        // this.setState({
        //   isResponseReceived:false
        // });
        setSuccessMessage('Registered user not found');
        setErrorMessage('');
        setIsResponseReceived(false);
        return false;
      });
  }

  // componentWillMount() {
  //   this.state = {
  //     isSendButtonDisabled: false,
  //     email: '',
  //     errorMessage: '',
  //     successMessage: '',
  //     isResponseReceived: false
  //   };
  // }

  // render () {
  return (
    <section class="row row-center has-text-center auth-section"
      style="background: #eee">
      <div class="column column-40 auth-center">
        <form class="box" name="forgotpasswordform" onSubmit={e => send(e)}>
          <div class="row">
            <div class="column auth-form">
              <div class="field">
                <input autofocus type="email" id="email" placeholder="Email" name="email" disabled={isResponseReceived} required />
                <label for="email" class="has-text-left" style="font-size:0.6em">Enter your registered Email</label>
              </div>
              <div id="error" class="error-color">{errorMessage}</div>
              <div id="error" class="success-color">{successMessage}</div>
              <button disabled={isSendButtonDisabled} class="button-margin is-fullwidth">Send</button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
  // }
};

export default ForgotPassword;
