/*
  Created BY: Prasannadatta kawadkar
  Created On: 13 Dec 2022
  Creation: Toggle Button component Created
*/
import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { Home, Globe, Truck, Bell, Logout, Kanban, Person, PrintBill, Bill, Consignment, Profile, AccountBalance, Change } from '../../components/svgs';
import { route } from 'preact-router';
import { removeAll } from '../../lib/myStore';
import http from 'fetch-bb';
import CONSTANTS from '../../lib/constants';
import { Toast } from '../../lib/toastr';

const SideMenu = (props) => {

  // let [ selectedListItem, setSelectedListItem ] = useState('Home');
  // let [ hovered, setHovered ] = useState(false);
  let [ pathName, setPathName ] = useState([]);

  /*
     Modified By: Manohar Sule
     Modified On: 29 Jan 2024
     Modification: When editing the Contact and refreshing the page, it navigates to the Home tab from the left navigation panel, but the Client Details view is displayed.
     Bug ID:ATC_BG_115
   */

  useEffect(() => {
    // onListItemSelect(window.location.pathname);
    let path = window.location.pathname.split('/');
    setPathName(path);
  }, [window.location.pathname]);

  const logout = async() => {
    try {
      await http.post(`${CONSTANTS.API_URL}/api/user/logout`);
      removeAll();
      new Toast('Successfully logged out', Toast.TYPE_DONE, Toast.TIME_LONG);
      route(`/`);
    }
    catch (HTTPException) {
      console.error(HTTPException);
      new Toast(HTTPException.message, Toast.TYPE_ERROR, Toast.TIME_LONG);
    }
  };

  // const onListItemSelect = async(title) => {
  //   if (title === 'Home' || title === '/home') {
  //     route('/home');
  //     await setSelectedListItem('Home');
  //   } else if (title === 'Kanban' || title === '/KanbanRoute') {
  //     route('/KanbanRoute');
  //     await setSelectedListItem('Kanban' );
  //   } else if (title === 'Cashsheet' || title === '/cashSheet') {
  //     route('/cashSheet');
  //     await setSelectedListItem('Cashsheet' );
  //   } else if (title === 'Consignments' || title === '/consignments') {
  //     route('/consignments');
  //     await setSelectedListItem('Consignments' );
  //   } else if (title === 'Bills' || title === '/bills') {
  //     route('/bills');
  //     await setSelectedListItem('Bills' );
  //   } else if (title === 'Bill Generation' || title === '/billGeneration') {
  //     route('/billGeneration');
  //     await setSelectedListItem('Bill Generation' );
  //   } else if (title === 'Manifests' || title === '/manifest') {
  //     route('/manifest');
  //     await setSelectedListItem('Manifests' );
  //   } else if (title === 'Clients' || title === '/clients') {
  //     route('/clients');
  //     await setSelectedListItem('Clients' );
  //   } else if (title === 'Logout') {
  //     logout();
  //     await setSelectedListItem('Logout' );
  //   } else if (title === 'changePassword' || title === '/verifyOldPassword' || title === '/changePassword') {
  //     route('/verifyOldPassword');
  //     await setSelectedListItem('changePassword' );
  //   } else if (title === 'Report' || title === '/report') {
  //     route('/report');
  //     await setSelectedListItem('Report');
  //   } else if (title === 'Tracking' || title === '/tracking') {
  //     route('/tracking');
  //     await setSelectedListItem('Tracking');
  //   }
  // };

  // const addClassOnHover = async(title) => {
  //   await setHovered(title);
  // };
  //
  // const removeClassOnHover = async() => {
  //   await setHovered('');
  // };

  return (
    <div class="menu-wrapper">
      <div class="company" style='height: 65px;'>
        <img src="../../assets/truck1.svg" class="company-logo"/> <span class="company-name">TRANS.PORT</span>
      </div>
      <ul class="list-style" style='overflow-y: auto;height: calc(100vh - 200px);'>
        <li class="list-item list-heading">
            MAIN MENU
        </li>
        {/*
          Bug: 16	ATC_UI_BG_16	Admin	Authentication Logout button - Sign in screen	When clicking on the logout button a two toaster message is displayed.
          Modification: Removed duplicate onclick on div inside li
          By: Devang
          Date: 10/08/2023
        */}
        <li class={pathName.includes('home') ? 'list-item-selected' : 'list-item'} onclick={() => route('/home')}>
          <div id='Home'>
            <Home height="20px" width="20px"/>
            <span class="align-text-svg">Home</span>
          </div>
        </li>
        <li class={pathName.includes('KanbanRoute') ? 'list-item-selected' : 'list-item'} onclick={() => route('/KanbanRoute')} >
          <div>
            <Kanban height="20px" width="20px"/>
            <span class="align-text-svg font-bold">Kanban</span>
          </div>
        </li>
        <li class={(pathName.includes('consignments') || pathName.includes('consignment')) ? 'list-item-selected' : 'list-item'} onclick={() => route('/consignments')}>
          <div>
            <Consignment height="20px" width="20px"/>
            <span class="align-text-svg font-bold">Consignments</span>
          </div>
        </li>
        <li class={(pathName.includes('clients') || pathName.includes('client')) ? 'list-item-selected' : 'list-item'} onclick={() => route('/clients')}>
          <div>
            <Person height="20px" width="20px"/>
            <span class="align-text-svg font-bold">Clients</span>
          </div>
        </li>
        <li class={pathName.includes('manifest') ? 'list-item-selected' : 'list-item'} onclick={() => route('/manifest')}>
          <div>
            <Truck height="20px" width="20px"/>
            <span class="align-text-svg font-bold">Manifests</span>
          </div>
        </li>
        <li class={pathName.includes('billGeneration') ? 'list-item-selected' : 'list-item'} onclick={() => route('/billGeneration')}>
          <div>
            <PrintBill height="20px" width="20px"/>
            <span class="align-text-svg font-bold">Bill Generation</span>
          </div>
        </li>
        <li class={pathName.includes('bills') ? 'list-item-selected' : 'list-item'} onclick={() => route("/bills")}>
          <div>
            <Bill height="20px" width="20px"/>
            <span class="align-text-svg font-bold">Bills</span>
          </div>
        </li>
        {/*
          BY: Prsannadatta Kawadkar
          ON: 08 Feb 2024
          Modification: commented tracking as it is not yet developed and comes in future work
        */}
        {/*<li class={pathName.includes('tracking') ? 'list-item-selected' : 'list-item'} onclick={() => route("/tracking")}>
          <div>
            <Globe height="20px" width="20px"/>
            <span class="align-text-svg font-bold">Tracking</span>
          </div>
        </li>*/}
        <li class={pathName.includes('cashSheet') ? 'list-item-selected' : 'list-item'} onclick={() => route("/cashSheet")}>
          <div>
            <AccountBalance height="20px" width="20px"/>
            <span class="align-text-svg font-bold">Cash Sheet</span>
          </div>
        </li>
        <li class={pathName.includes('report') ? 'list-item-selected' : 'list-item'} onclick={() => route("/report")}>
          <div>
            <Consignment height="20px" width="20px"/>
            <span class="align-text-svg font-bold">Report</span>
          </div>
        </li>
      </ul>
      <ul class="list-style" style="height: 120px;position: absolute;bottom: 0; left: 0; right: 0 ">
        <li class="list-item list-heading fs-12 font-bold">
            GENERAL
        </li>
        <li class={pathName.includes('verifyOldPassword') ? 'list-item-selected' : 'list-item'} onclick={() => route("/verifyOldPassword")}>
          <div>
            <Change height="20px" width="20px"/>
            <span class="align-text-svg font-bold">Change Password</span>
          </div>
        </li>
        <li class={'list-item'} onclick={() => logout()}>
          <div>
            <Logout height="20px" width="20px"/>
            <span class="align-text-svg font-bold">Logout</span>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default SideMenu;
