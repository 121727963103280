import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const Person = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg class={props.classes || ""} onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "512pt"} height={props.height || "512pt"}><path d="M13.25 6.708V4.25h-6.5v2.458H5V3.375q0-.354.26-.615.261-.26.615-.26h8.25q.354 0 .615.26.26.261.26.615v3.333Zm-9.833 1.75H16.583 5.167ZM15 10.646q.354 0 .615-.261.26-.26.26-.614t-.26-.615q-.261-.26-.615-.26t-.615.26q-.26.261-.26.615t.26.614q.261.261.615.261ZM6.75 15.75h6.5v-2.917h-6.5v2.917Zm0 1.75q-.729 0-1.24-.51Q5 16.479 5 15.75v-1.312H2.542q-.354 0-.615-.261-.26-.26-.26-.615V9.271q0-1.063.75-1.813t1.812-.75h11.542q1.062 0 1.812.75t.75 1.813v4.291q0 .355-.26.615-.261.261-.615.261H15v1.312q0 .729-.51 1.24-.511.51-1.24.51Zm9.833-4.812V9.354q0-.375-.218-.635-.219-.261-.594-.261H4.312q-.374 0-.635.261-.26.26-.26.635v3.334H5v-1.605h10v1.605Z"/></svg>
    </span>
  );
};

export default Person;
