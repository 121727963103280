import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const Home = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg class={props.classes || ""} onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "512pt"} height={props.height || "512pt"}><path d="M5.083 15.75h2.334v-5h5.166v5h2.334V8.375L10 4.688 5.083 8.375Zm0 1.75q-.729 0-1.239-.51-.511-.511-.511-1.24V8.375q0-.417.188-.781.187-.365.521-.615l4.916-3.687q.23-.167.49-.261.26-.093.552-.093.292 0 .552.093.26.094.49.261l4.916 3.687q.334.25.521.615.188.364.188.781v7.375q0 .729-.511 1.24-.51.51-1.239.51h-4.084v-5H9.167v5ZM10 10.208Z"/></svg>
    </span>
  );
};

export default Home;
