import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const Consignment = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg class={props.classes || ""} onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "512pt"} height={props.height || "512pt"}><path d="M6.708 14.167h4.125q.355 0 .615-.261.26-.26.26-.614t-.26-.615q-.26-.26-.615-.26H6.708q-.354 0-.614.26-.261.261-.261.615t.261.614q.26.261.614.261Zm0-3.292h6.584q.354 0 .614-.26.261-.261.261-.615t-.261-.615q-.26-.26-.614-.26H6.708q-.354 0-.614.26-.261.261-.261.615t.261.615q.26.26.614.26Zm0-3.292h6.584q.354 0 .614-.26.261-.261.261-.615t-.261-.614q-.26-.261-.614-.261H6.708q-.354 0-.614.261-.261.26-.261.614t.261.615q.26.26.614.26ZM4.25 15.75h11.5V4.25H4.25v11.5Zm0-11.5v11.5-11.5Zm0 13.25q-.729 0-1.24-.51-.51-.511-.51-1.24V4.25q0-.729.51-1.24.511-.51 1.24-.51h3.417q.25-.75.895-1.208Q9.208.833 10 .833q.792 0 1.438.459.645.458.895 1.208h3.417q.729 0 1.24.51.51.511.51 1.24v11.5q0 .729-.51 1.24-.511.51-1.24.51ZM10 3.625q.25 0 .438-.187.187-.188.187-.438t-.187-.438q-.188-.187-.438-.187t-.438.187q-.187.188-.187.438t.187.438q.188.187.438.187Z"/></svg>
    </span>
  );
};

export default Consignment;
