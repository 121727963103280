import { h } from "preact";
import { useState, useEffect } from "preact/hooks";
import { route } from 'preact-router';
import { setItem, removeAll } from '../../lib/myStore';
import { startLoader, stopLoader } from '../../lib/utils';
import { Toast } from '../../lib/toastr';
import CONSTANTS from '../../lib/constants';
import http from 'fetch-bb';

//export default class Header1 extends Component {
const Header1 = (props) => {

  let [ show, setShow ] = useState(false);
  let [ userinfo, setUserinfo ] = useState({});
  let [ navMenu, setNavMenu ] = useState(false);
  async function getUserInfo() {
    try {
      let userinfo = await http.get(`${CONSTANTS.API_URL}/api/admin/user/me`);
      setItem('userinfo', userinfo);
      //this.setState({userInfo});
      setUserinfo(userinfo);
    }
    catch (HTTPException) {
      console.error(HTTPException.message);
    }
  }

  // function componentDidMount() {
  //   getUserInfo();
  // }

  useEffect(() => {
    getUserInfo();
  }, []);

  function toggleMenu() {
    // this.setState({
    //   navMenu: !this.state.navMenu
    // });
    setNavMenu(!navMenu);
  }

  async function logout() {
    try {
      startLoader();
      await http.post(`${CONSTANTS.API_URL}/api/admin/logout`);
      stopLoader();
      removeAll();
      new Toast('Successfully logged out', Toast.TYPE_DONE, Toast.TIME_LONG);
      route(`/admin/login`);
    } catch (HTTPException) {
      stopLoader();
      console.error(HTTPException);
      new Toast(HTTPException.message, Toast.TYPE_ERROR, Toast.TIME_LONG);
    }
  }

  function search(e) {
    e.preventDefault();
    route('/globalSearch');
  }

  function myFunction() {
    //this.setState({show: !this.state.show});
    setShow(!show);
  }

  // componentWillMount() {
  //   this.state = {
  //     show: false,
  //     userinfo: {}
  //   };
  // }

  function openNav() {
    let sideNavDiv = Array.prototype.slice.call(document.querySelectorAll('.gn-menu-main'), 0);
    if (sideNavDiv.length > 0) {
      document.getElementById("gn-menu-a").classList.toggle('gn-selected');
      document.getElementById("gn-menu-nav").classList.toggle('gn-open-all');
    }
    if ( document.getElementById("gn-menu-nav").classList.contains('gn-open-all') ) {
      let contentDiv = Array.prototype.slice.call(document.querySelectorAll('.outer-most-div'), 0);
      if (contentDiv.length > 0) {
        let $target = document.getElementById('main-body');
        $target.classList.remove('margin-left-76');
        $target.classList.add('margin-left-266');
      }
    } else {
      document.getElementById("main-body").classList.remove('margin-left-266');
      document.getElementById("main-body").classList.add('margin-left-76');
    }


    // let contentDiv = Array.prototype.slice.call(document.querySelectorAll('.outer-most-div'), 0);
    // if (contentDiv.length > 0) {
    //   let $target = document.getElementById('main-body');
    //   $target.classList.toggle('margin-left-200');
    // }
  }


  //render() {
  return (
    <div class="container">
      <ul id="gn-menu" class="gn-menu-main gn-selected" style="width:100%">
        <li class="gn-trigger">
          <a id="gn-menu-a" class="gn-icon gn-icon-menu" onClick={() => openNav()}><span>Menu</span></a>
          <nav id="gn-menu-nav" class="gn-menu-wrapper gn-open-part">
            <div class="gn-scroller">
              <ul class="gn-menu">
                <li>
                  <a class="gn-icon icon-paper-plane-o" href="/admin/companies">Companies</a>
                </li>
                <li>
                  <a class="gn-icon icon-user" onClick={() => logout()}>Logout</a>
                </li>
              </ul>
            </div>
          </nav>
        </li>
        <li style="float:right; border-left: 1px solid #c6d0da;">
          <a class="codrops-icon codrops-icon-prev" href="/home" style="padding:6px 15px">
            <img src="../assets/static/atc.jpg" style="width:100px" />
          </a>
        </li>
        <li style="float:right; border-left: 1px solid #c6d0da;"><a class="codrops-icon codrops-icon-prev">
          <span>{userinfo.displayName}</span></a></li>
        <li class="codrops-icon codrops-icon-prev search-box">
          <em class="icon icon-search" style="top:0"/>
          <input type="text" id="search" placeholder="Search" name="search" value="" style="border:none"/>
        </li>
      </ul>
    </div>
  );
  //}
};

export default Header1;
