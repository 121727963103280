import { h } from "preact";
import { useState, useEffect } from "preact/hooks";

//export default class Footer extends Component {
const Footer = (props) => {
  //render({}, {}) {
  return (
    <div>
      <section class="body-footer">
        <p>Powered By <span>TechNative</span></p>
      </section>
    </div>
  );
  //}
};

export default Footer;
