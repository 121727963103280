import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const Truck = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg class={props.classes || ""} onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "512pt"} height={props.height || "512pt"}><path d="M5.083 16.667q-1.041 0-1.781-.719t-.74-1.76q-.854 0-1.291-.511-.438-.51-.438-1.239V5.083q0-.729.511-1.239.51-.511 1.239-.511h9.875q.73 0 1.24.511.51.51.51 1.239v1.584h2.104q.209 0 .396.093.188.094.313.261L19 9.646q.083.104.125.239.042.136.042.282v3.145q0 .355-.261.615-.26.261-.614.261H17.5q0 1.041-.74 1.76-.739.719-1.781.719-1.041 0-1.781-.719t-.74-1.76H7.604q0 1.041-.739 1.76-.74.719-1.782.719Zm0-1.75q.313 0 .542-.229.229-.23.229-.542 0-.313-.229-.542-.229-.229-.542-.229-.312 0-.541.229-.23.229-.23.542 0 .312.23.542.229.229.541.229Zm-2.5-9.834v7.292h.709q.354-.354.812-.552.458-.198.979-.198.5 0 .969.198t.844.552h5.562V5.083H2.583Zm12.396 9.834q.313 0 .542-.229.229-.23.229-.542 0-.313-.229-.542-.229-.229-.542-.229-.312 0-.541.229-.23.229-.23.542 0 .312.23.542.229.229.541.229Zm-.771-4.125h3.438l-1.771-2.375h-1.667ZM7.521 8.729Z"/></svg>  </span>
  );
};

export default Truck;
