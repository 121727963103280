import { h } from 'preact';
import { useState, useEffect } from "preact/hooks";
import { route } from 'preact-router';
import CONSTANTS from '../../../lib/constants';
import { AppStore } from '../../../lib/store';
import { Toast } from '../../../lib/toastr';
import http from 'fetch-bb';

// export default class setPassword extends Component {
const setPassword = (props) => {

  let [ userID, setUserID ] = useState('');
  let [ tokenToGetUser, setTokenToGetUser ] = useState(AppStore.get('tokenForGettingUser'));
  let [ errorMessage, setErorMessage ] = useState('');
  let [ successMessage, setSuccessMessage ] = useState('');
  let [ newPassword, setNewPassword ] = useState('');
  let [ confirmPassword, setConfirmPassword ] = useState('');

  function savePasswordAndRedirectToLogin(e) {
    e.preventDefault();
    return http
      .put(`${CONSTANTS.API_URL}/api/admin/user/${userID}/setpassword`,
        {
          newPassword: e.target.newPassword.value,
          confirmpassword: e.target.confirmNewPassword.value
        })
      .then(() => {
        new Toast('Your password has been successfully set. You can login now.', Toast.TYPE_DONE, Toast.TIME_LONG);
        // this.setState({errorMessage: ''});
        setErrorMessage('');
        route(`/admin/login`);
        AppStore.removeAll();
      })
      .catch((HTTPException) => {
        if (HTTPException.statusCode === 409) {
          // this.setState({
          //   isResponseReceived:false,
          //   isButtonClicked: false
          // });
          setIsResponseReceived(false);
          setIsButtonClicked(false);
          new Toast('Passwords do not match', Toast.TYPE_ERROR, Toast.TIME_LONG);
          setErrorMessage('Passwords do not match');
          setSuccessMessage('');
          return '';
          // return this.setState({errorMessage: 'Passwords do not match', successMessage: ''});
        }
        else if (HTTPException.statusCode === 400) {
          // this.setState({
          //   isResponseReceived:false,
          //   isButtonClicked: false
          // });
          setIsResponseReceived(false);
          setIsButtonClicked(false);
          new Toast('Passwords must contain At least 6 characters, including UPPERCASE, lowercase letters, '+
           'special characters and Digits', Toast.TYPE_ERROR, Toast.TIME_LONG);
          setErrorMessage('Passwords must contain At least 6 characters, including UPPERCASE, lowercase letters, special characters and Digits');
          setSuccessMessage('');
          // return this.setState({errorMessage: 'Passwords must contain At least 6 characters, including UPPERCASE, lowercase letters, '+
          //   'special characters and Digits', successMessage: ''});
        }
        // this.setState({
        //   isResponseReceived: false,
        //   isButtonClicked: false
        // });
        setIsResponseReceived(false);
        setIsButtonClicked(false);
      });
  }

  function getSiloUser() {
    return http
      .get(`${CONSTANTS.API_URL}/api/userByToken/${tokenToGetUser}`)
      .then((uid) => {
        // this.setState({userID: uid.uuid});
        setUserID(uid.uuid);
      })
      .catch((DBEXception) => {
        new Toast(DBEXception.message, Toast.TYPE_ERROR, Toast.TIME_LONG);
        console.error(DBEXception);
      });
  }

  // componentWillMount() {
  //   this.state = {
  //     userID: '',
  //     tokenToGetUser: '',
  //     errorMessage: '',
  //     successMessage: ''
  //   };
  //   this.setState({tokenToGetUser: AppStore.get('tokenForGettingUser')});
  // }

  // componentDidMount() {
  //   getSiloUser();
  // }

  useEffect(() => {
    getSiloUser();
  }, []);

  // render () {
  return (
    <section class="row row-center has-text-center auth-section">
      <div class="column column-40 auth-center">
        <form onSubmit={e => savePasswordAndRedirectToLogin(e)}>
          <div class="row box">
            <div class="column">
              <div class="row">
                <div class="column">
                  <h6 class="inline">Set Your Password</h6>
                </div>
              </div>
              <div class="row">
                <div class="column auth-form">
                  <div class="field">
                    <input value={newPassword} name="newPassword" id="password" type="password" placeholder="New Password" required/>
                    <label for="password" class="has-text-left" style="font-size:0.8em">New Password</label>
                  </div>
                  <div class="field">
                    <input value={confirmPassword} name="confirmNewPassword" id="confirmNewPassword"
                      type="password" placeholder="Confirm New Password" required/>
                    <label for="confirmNewPassword" class="has-text-left" style="font-size:0.8em">Confirm New Password</label>
                  </div>
                  {errorMessage && (
                    <div id="error" class="error-color">
                      <span>
                        {errorMessage}
                      </span>
                    </div>
                  )}
                  {successMessage && (
                    <div>
                      <span>{successMessage}

                      </span>
                    </div>
                  )}
                  <button disabled={!userID} type="submit" id="confirmButton" class="is-fullwidth">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
  // }
};

export default setPassword;
