import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const Bill = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg class={props.classes || ""} onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "512pt"} height={props.height || "512pt"}><path d="M5.083 18.333q-1.083 0-1.823-.739-.739-.74-.739-1.823v-1.813q0-.354.26-.614.261-.261.615-.261h1.583V2.354q0-.312.177-.323.177-.01.406.219l.667.667L7.146 2q.146-.146.333-.146.188 0 .333.146l.917.917L9.646 2q.146-.146.333-.146.188 0 .333.146l.917.917.917-.917q.146-.146.333-.146.188 0 .333.146l.917.917.917-.917q.146-.146.333-.146.188 0 .333.146l.917.917.667-.667q.229-.229.406-.208.177.02.177.312v13.417q0 1.083-.739 1.823-.74.739-1.823.739Zm9.834-1.75q.354 0 .583-.229.229-.229.229-.583V4.229h-9v8.854h6.5q.354 0 .615.261.26.26.26.614v1.813q0 .354.229.583.229.229.584.229Zm-6.584-9q-.354 0-.604-.26-.25-.261-.25-.615t.261-.614q.26-.261.614-.261h3.271q.354 0 .615.261.26.26.26.614t-.26.615q-.261.26-.615.26Zm0 2.521q-.354 0-.604-.26-.25-.261-.25-.615t.261-.614q.26-.261.614-.261h3.271q.354 0 .615.261.26.26.26.614t-.26.615q-.261.26-.615.26Zm5.813-2.521q-.354 0-.615-.26-.26-.261-.26-.615t.26-.614q.261-.261.615-.261t.614.261q.261.26.261.614t-.261.615q-.26.26-.614.26Zm0 2.521q-.354 0-.615-.26-.26-.261-.26-.615t.26-.614q.261-.261.615-.261t.614.261q.261.26.261.614t-.261.615q-.26.26-.614.26Zm-9.063 6.479h7.271v-1.75H4.271v.938q0 .354.229.583.229.229.583.229Zm-.812 0v-1.75 1.75Z"/></svg>
    </span>
  );
};

export default Bill;
