import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const Person = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg class={props.classes || ""} onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "512pt"} height={props.height || "512pt"}><path d="M10 9.979q-1.417 0-2.375-.958-.958-.959-.958-2.375 0-1.417.958-2.375.958-.959 2.375-.959t2.375.959q.958.958.958 2.375 0 1.416-.958 2.375-.958.958-2.375.958Zm4.979 6.688H5.021q-.729 0-1.24-.511-.51-.51-.51-1.239v-.646q0-.729.375-1.313.375-.583.979-.875 1.271-.604 2.635-.927 1.365-.323 2.74-.323 1.375 0 2.75.323t2.625.927q.604.292.979.875.375.584.375 1.313v.646q0 .729-.51 1.239-.511.511-1.24.511Zm-9.958-1.75h9.958v-.646q0-.229-.104-.396t-.271-.208q-1.083-.521-2.271-.802-1.187-.282-2.333-.282-1.146 0-2.333.292-1.188.292-2.271.792-.167.083-.271.229-.104.146-.104.375ZM10 8.229q.688 0 1.135-.448.448-.448.448-1.135 0-.688-.448-1.136-.447-.448-1.135-.448t-1.135.448q-.448.448-.448 1.136 0 .687.448 1.135.447.448 1.135.448Zm0-1.583Zm0 8.271Z"/></svg></span>
  );
};

export default Person;
