import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const RightArrow = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path d="M14.979 10.625H3.375q-.354 0-.615-.26-.26-.261-.26-.615t.26-.615q.261-.26.615-.26h11.604l-1.271-1.271q-.25-.25-.25-.594 0-.343.271-.614.25-.25.615-.271.364-.021.614.25l2.75 2.75q.125.125.198.292.073.166.073.333t-.073.333q-.073.167-.198.292l-2.75 2.75q-.25.25-.604.25t-.625-.271q-.25-.271-.25-.625t.25-.604Z"/></svg>
    </span>
  );
};

export default RightArrow;
