import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const Change = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path d="M12.917 11.771q.291.208.593.114.302-.093.428-.427.145-.354.208-.729.062-.375.062-.729 0-.812-.302-1.594-.302-.781-.927-1.385-.604-.583-1.344-.886-.739-.302-1.489-.323l.292-.312q.187-.188.187-.458 0-.271-.208-.48-.188-.187-.459-.187-.27 0-.458.187L7.854 6.188q-.146.145-.146.333 0 .187.146.333L9.5 8.479q.188.188.458.188.271 0 .48-.209.187-.187.187-.468 0-.282-.187-.469l-.417-.396q.541.021 1.073.219.531.198.948.614.416.417.625.948.208.532.208 1.073 0 .271-.042.521-.041.25-.145.5-.084.229-.021.438.062.208.25.333Zm-3.334 3.667q.188.187.459.187.27 0 .458-.187l1.646-1.626q.146-.145.146-.333 0-.187-.146-.333L10.5 11.521q-.188-.188-.448-.198-.26-.011-.469.198-.187.187-.187.469 0 .281.187.468l.396.417q-.541-.021-1.073-.219-.531-.198-.948-.614-.416-.417-.625-.948-.208-.532-.208-1.073 0-.271.042-.521.041-.25.145-.5.084-.229 0-.448-.083-.219-.27-.344-.292-.187-.573-.083-.281.104-.407.417-.145.354-.208.729-.062.375-.062.729 0 .812.302 1.594.302.781.927 1.385.604.583 1.344.886.739.302 1.489.323l-.292.312q-.187.188-.187.458 0 .271.208.48ZM10 18.333q-1.729 0-3.25-.656t-2.646-1.781q-1.125-1.125-1.781-2.646-.656-1.521-.656-3.25t.656-3.25q.656-1.521 1.781-2.646T6.75 2.323q1.521-.656 3.25-.656t3.25.656q1.521.656 2.646 1.781t1.781 2.646q.656 1.521.656 3.25t-.656 3.25q-.656 1.521-1.781 2.646t-2.646 1.781q-1.521.656-3.25.656ZM10 10Zm0 6.583q2.729 0 4.656-1.927 1.927-1.927 1.927-4.656 0-2.729-1.927-4.656Q12.729 3.417 10 3.417q-2.729 0-4.656 1.927Q3.417 7.271 3.417 10q0 2.729 1.927 4.656Q7.271 16.583 10 16.583Z"/></svg>
    </span>
  );
};

export default Change;
