import { h } from "preact";
import { useState, useEffect } from "preact/hooks";

// export default class NotFound extends Component {
const NotFound = (props) => {
  // render(props) {
  return (
    <div class="container">
      <div class="row">
        <h1> {props.type} Error </h1>
      </div>
      <div class="row">
          Page Not Found
      </div>
    </div>
  );
  // }
};

export default NotFound;
