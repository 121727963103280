import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const Next = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path d="M8.104 13.625q-.271-.271-.271-.646t.271-.646l1.458-1.458H3.375q-.354 0-.615-.26-.26-.261-.26-.615t.26-.615q.261-.26.615-.26h6.187L8.083 7.646q-.271-.271-.26-.636.01-.364.281-.635t.646-.271q.375 0 .646.271l3 3q.146.146.208.302.063.156.063.323t-.063.323q-.062.156-.208.302l-3.021 3.021q-.271.271-.635.26-.365-.01-.636-.281ZM4.25 17.5q-.729 0-1.24-.51-.51-.511-.51-1.24V13.5q0-.354.26-.615.261-.26.615-.26t.615.26q.26.261.26.615v2.25h11.521V4.25H4.25V6.5q0 .354-.26.615-.261.26-.615.26t-.615-.26Q2.5 6.854 2.5 6.5V4.25q0-.729.51-1.24.511-.51 1.24-.51h11.521q.729 0 1.239.51.511.511.511 1.24v11.5q0 .729-.511 1.24-.51.51-1.239.51Z"/></svg> </span>
  );
};

export default Next;
