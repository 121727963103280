import { h } from "preact";
import { useState, useEffect } from "preact/hooks";
import {formatDateTime} from '../../lib/utils';

// export default class LorryReceipt extends Component {
const LorryReceipt = (props) => {
  //render({}, {}) {
  const billType = {
    toPay: 'ToPay',
    toBeBilled: 'To Be Billed',
    paid: 'Paid',
    advancePaid: 'Advance Paid'
  };
  return (
    <div>
      <div class="row">
        <div class="column no-padding">
          <div><img src="../assets/static/atc.jpg" style="width:100px" /></div>
        </div>
        <div class="column has-text-right">
          <div>LR No.: <strong>{props.lrDetails.lorryReceiptNumber}</strong></div>
          <div>Date: <strong>{formatDateTime(props.lrDetails.bookingDate)}</strong></div>
        </div>
      </div>
      <div class="row border-black">
        <div class="column no-padding">
          <div class="row border-bottom-black">
            <div class="column column-80 border-right-black">
              <div>Consigner Name: <strong>{props.lrDetails.consignerName}</strong></div>
            </div>
            <div class="column">
              <div>From: <strong>{props.lrDetails.fromAddress}</strong></div>
            </div>
          </div>
          <div class="row border-bottom-black">
            <div class="column column-80 border-right-black">
              <div>Consignee Name: <strong>M/s Mr.</strong>{props.lrDetails.consigneeName}</div>
            </div>
            <div class="column">
              <div>To: <strong>{props.lrDetails.toAddress}</strong></div>
            </div>
          </div>
          <div class="row">
            <div class="column no-padding">
              <div class="row">
                <div class="column column-33 no-padding border-right-black">
                  <div class="row border-bottom-black">
                    <div class="column border-right-black">
                      <div>No. of Boxes: <strong>{props.lrDetails.numberOfPackages}</strong></div>
                    </div>
                    <div class="column">
                      <div>Description<br/>{props.lrDetails.packageDetails ? props.lrDetails.packageDetails.description : ''}</div>
                    </div>
                  </div>
                  <div class="row border-bottom-black">
                    <div class="column">
                      <div>Name: <strong>{props.lrDetails.packageDetails ? props.lrDetails.packageDetails.description : ''}</strong></div>
                    </div>
                  </div>
                  <div class="row border-bottom-black">
                    <div class="column">
                      <div>Invoice/DC No: <strong>{props.lrDetails.invoiceNumber}</strong></div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="column">
                      <div>Value: Rs.<strong>{props.lrDetails.invoiceValue}</strong></div>
                    </div>
                    <div class="column">
                      <div>E way bill : <strong>{props.lrDetails.ewayBillNumber}</strong></div>
                    </div>
                  </div>
                </div>
                <div class="column no-padding border-right-black">
                  <div class="row border-bottom-black">
                    <div class="column">
                      <div>Weight/Volumn 1 Cubic ft=20kg</div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="column">
                      <div>Actual Wt</div>
                      <div><strong>{props.lrDetails.actualWeight ? props.lrDetails.actualWeight  + ' ' : ' '}{props.lrDetails.packageDetails[0] ? props.lrDetails.packageDetails[0].selectedUnitForWeight: ''}</strong></div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="column">
                      <div>Charged Wt</div>
                      {/*
                        T1532: Charged Weight is not "0" KG
                        Rutuja
                        5/3/24
                      */}
                      <div><strong>{props.lrDetails.chargedWeight ? props.lrDetails.chargedWeight + '0' : '0'}{props.lrDetails.packageDetails[0] ? props.lrDetails.packageDetails[0].selectedUnitForWeight: 'KG '}</strong></div>
                    </div>
                  </div>
                </div>
                <div class="column no-padding border-right-black">
                  <div class="row border-bottom-black">
                    <div class="column">
                      <div>Freight:</div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="column">
                      <div> {billType[props.lrDetails.billType]}</div>
                      {/*<div>Paid / {this.props.lrDetails.billType}</div>
                        <div>To Be Billed {this.props.lrDetails.billType}</div>*/}
                    </div>
                  </div>
                </div>
                <div class="column column-40 no-padding">
                  <div class="row">
                    <div class="column no-padding border-right-black">
                      <div class="row border-bottom-black">
                        <div class="column no-padding">
                          <div>Rate per kg</div>
                        </div>
                      </div>
                      <div class="row border-bottom-black">
                        <div class="column no-padding">
                          <div>Basic Freight</div>
                        </div>
                      </div>
                      <div class="row border-bottom-black">
                        <div class="column no-padding">
                          <div>Handling Charges</div>
                        </div>
                      </div>
                      <div class="row border-bottom-black">
                        <div class="column np-padding">
                          <div>DC // DD</div>
                        </div>
                      </div>
                      <div class="row border-bottom-black">
                        <div class="column no-padding">
                          <div>LR Charges</div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="column no-padding">
                          <div>Total</div>
                        </div>
                      </div>
                    </div>
                    <div class="column no-padding">
                      <div class="row border-bottom-black">
                        <div class="column">
                          <div>Amount Rs.</div>
                        </div>
                      </div>
                      <div class="row border-bottom-black">
                        <div class="column">
                          <div>{props.lrDetails.basicFreightCharges ? props.lrDetails.basicFreightCharges : 0}</div>
                        </div>
                      </div>
                      <div class="row border-bottom-black">
                        <div class="column">
                          <div>{props.lrDetails.handlingCharges ? props.lrDetails.handlingCharges : '0'}</div>
                        </div>
                      </div>
                      <div class="row border-bottom-black">
                        <div class="column">
                          <div>{(props.lrDetails.drDDCharges ? props.lrDetails.drDDCharges : 0) + (props.lrDetails.ddCharges ? props.lrDetails.ddCharges : 0)}</div>
                        </div>
                      </div>
                      <div class="row border-bottom-black">
                        <div class="column">
                          <div>{props.lrDetails.lrCharges ? props.lrDetails.lrCharges : '0'}</div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="column">
                          <div>{props.lrDetails.grandTotal}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="column no-padding">
          <div>Receiver Signature & Seal</div>
        </div>
        <div class="column has-text-right no-padding">
          <div>for <strong>Air Transport  Corporation (Assam) Pvt. Ltd.</strong></div>
        </div>
      </div>
    </div>
  );
  // }
};

export default LorryReceipt;
