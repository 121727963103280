import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const Kanban = (props) => {
  return (
    <span class={props.masterClasses || ''} title={props.title || ''}>
      <svg onclick={() => props.action && props.action()} class={`${props.classes || ""}`} xmlns="http://www.w3.org/2000/svg" width={props.width || "20px"} height={props.height || "20px"}><path d="M6.708 14.167q.354 0 .615-.261.26-.26.26-.614V6.708q0-.354-.26-.614-.261-.261-.615-.261t-.614.261q-.261.26-.261.614v6.584q0 .354.261.614.26.261.614.261ZM10 10q.354 0 .615-.26.26-.261.26-.615V6.708q0-.354-.26-.614-.261-.261-.615-.261t-.615.261q-.26.26-.26.614v2.417q0 .354.26.615.261.26.615.26Zm3.292 2.5q.354 0 .614-.26.261-.261.261-.615V6.708q0-.354-.261-.614-.26-.261-.614-.261t-.615.261q-.26.26-.26.614v4.917q0 .354.26.615.261.26.615.26Zm-9.042 5q-.729 0-1.24-.51-.51-.511-.51-1.24V4.25q0-.729.51-1.24.511-.51 1.24-.51h11.5q.729 0 1.24.51.51.511.51 1.24v11.5q0 .729-.51 1.24-.511.51-1.24.51Zm0-1.75h11.5V4.25H4.25v11.5Zm0 0V4.25v11.5Z"/></svg>

    </span>
  );
};

export default Kanban;
