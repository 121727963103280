import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
// from flaticon
const AccountBalance = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg class={props.classes || ""} onclick={(e) => props.action && props.action(e)} xmlns="http://www.w3.org/2000/svg" version="1.0" width={props.width || "512pt"} height={props.height || "512pt"}><path d="M5 14q-.354 0-.615-.26-.26-.261-.26-.615V9.417q0-.355.26-.605.261-.25.615-.25t.615.261q.26.26.26.615v3.708q0 .354-.26.604Q5.354 14 5 14Zm5.021 0q-.354 0-.615-.26-.26-.261-.26-.615V9.417q0-.355.26-.605.261-.25.615-.25t.614.261q.261.26.261.615v3.708q0 .354-.261.604-.26.25-.614.25Zm-7.5 3.5q-.354 0-.604-.26-.25-.261-.25-.615t.26-.615q.261-.26.615-.26H17.5q.354 0 .604.26.25.261.25.615t-.26.615q-.261.26-.615.26Zm12.5-3.5q-.354 0-.615-.26-.26-.261-.26-.615V9.417q0-.355.26-.605.261-.25.615-.25t.614.261q.261.26.261.615v3.708q0 .354-.261.604-.26.25-.614.25ZM10.812 1.229l6.771 3.438q.334.166.552.448.219.281.219.635 0 .438-.312.75-.313.312-.771.312H2.729q-.437 0-.75-.312-.312-.312-.312-.75 0-.354.218-.635.219-.282.553-.448l6.791-3.438q.375-.208.792-.198.417.011.791.198ZM5.5 5.062h9.021Zm0 0h9.021l-4.5-2.27Z"/></svg>
    </span>
  );
};

export default AccountBalance;
