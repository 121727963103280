import { h } from 'preact';
import { useState, useEffect } from "preact/hooks";
import CONSTANTS from '../../../lib/constants';
import { route } from 'preact-router';
import { Link } from 'preact-router';
import http from 'fetch-bb';
// import { AppStore } from '../../lib/store';

let resetToken;

// export default class ResetPassword extends Component {
const ResetPassword = (props) => {

  let [ newPassword, setNewPassword ] = useState('');
  let [ confirmPassword, setConfirmPassword ] = useState('');
  let [ errorMessage, setErrorMessage ] = useState('');
  let [ successMessage, setSuccessMessage ] = useState('');
  let [ isButtonClicked, setIsButtonClicked ] = useState(false);
  let [ isResponseReceived, setIsResponseReceived ] = useState(false);


  function resetPassword(e) {
    e.preventDefault();
    // this.setState({
    //   isResponseReceived:true,
    //   isButtonClicked: true
    // });
    // this.setState({newPassword: e.target.newPassword.value});
    // this.setState({confirmPassword: e.target.confirmPassword.value});

    setIsResponseReceived(true);
    setIsButtonClicked(true);
    setNewPassword(e.target.newPassword.value);
    setConfirmPassword(e.target.confirmPassword.value);

    // if (this.state.newPassword !== this.state.confirmPassword) {
    //   this.setState({
    //     isResponseReceived:false,
    //     isButtonClicked: false
    //   });
    //   return this.setState({errorMessage: 'Passwords do not match'});
    // }
    return http
      .post(`${CONSTANTS.API_URL}/api/admin/resetpassword?resetToken`, {
        newPassword: e.target.newPassword.value,
        confirmNewPassword: e.target.confirmPassword.value,
        resetToken: props.matches.resetToken
      })
      .then(() => {
        // this.setState({successMessage: 'Your password has been reset. You can now proceed to '});
        // this.setState({errorMessage: ''});
        // this.setState({
        //   isResponseReceived:false,
        //   isButtonClicked: false
        // });
        setSuccessMessage('Your password has been reset. You can now proceed to');
        setErrorMessage('');
        setIsResponseReceived(false);
        setIsButtonClicked(false);
      })
      .catch((HTTPException) => {
        if (HTTPException.statusCode === 409) {
          // this.setState({
          //   isResponseReceived:false,
          //   isButtonClicked: false
          // });
          setSuccessMessage('');
          setErrorMessage('Passwords do not match');
          setIsResponseReceived(false);
          setIsButtonClicked(false);
          return '';
          // return this.setState({errorMessage: 'Passwords do not match', successMessage: ''});
        }
        else if (HTTPException.statusCode === 400) {
          // this.setState({
          //   isResponseReceived:false,
          //   isButtonClicked: false
          // });
          setIsResponseReceived(false);
          setIsButtonClicked(false);
          setSuccessMessage('');
          setErrorMessage('Passwords must contain At least 6 characters,including UPPERCASE, lowercase letters, special characters and Digits');
          return '';
          // return this.setState({errorMessage: 'Passwords must contain At least 6 characters,including UPPERCASE, lowercase letters, '+
          //  'special characters and Digits', successMessage: ''});
        }
        // this.setState({
        //   isResponseReceived: false,
        //   isButtonClicked: false
        // });
        setIsResponseReceived(false);
        setIsButtonClicked(false);
        setSuccessMessage('');
        setErrorMessage('This link is expired.');
        return '';
        // return this.setState({errorMessage: 'This link is expired. Please ', successMessage: ''});
      });
  }
  // componentWillMount() {
  //   this.state = {
  //     newPassword: '',
  //     confirmPassword: '',
  //     errorMessage: '',
  //     successMessage: '',
  //     isButtonClicked: false,
  //     isResponseReceived: false
  //   };
  // }
  // componentDidMount() {
  //   resetToken = this.props.matches.resetToken;
  //   if (!resetToken) {
  //     route(`/admin/forgotpassword`);
  //   }
  // }

  useEffect(() => {
    resetToken = props.matches.resetToken;
    if (!resetToken) {
      route(`/admin/forgotpassword`);
    }
  }, [props]);

  // render () {
  return (
    <section class="row row-center has-text-center auth-section"
      style="background: #eee">
      <div class="column column-40 auth-center">
        <form class="box" name="resetpasswordform" onSubmit={e => resetPassword()}>
          <div class="row">
            <div class="column auth-form">
              <div class="field">
                <input autofocus type="password" placeholder="New Password" name="newPassword" disabled={isResponseReceived} required />
                <label for="email" class="has-text-left" style="font-size:0.6em">Enter New Password</label>
              </div>
              <div class="field">
                <input autofocus type="password" placeholder="Confirm Password" name="confirmPassword" disabled={isResponseReceived} required />
                <label for="email" class="has-text-left" style="font-size:0.6em">Confirm Password</label>
              </div>

              {errorMessage && (
                <div id="error" class="error-color">
                  <span>
                    {errorMessage}
                    {
                      errorMessage === 'This link is expired. Please ' &&
                        (<Link class="hyperlink" href={`/${shortName}/forgotpassword`}>generate a new one</Link>)
                    }
                  </span>
                </div>
              )}
              {successMessage && (
                <div>
                  <span>{successMessage}
                    <Link class="hyperlink" href="/admin/login">Login</Link>
                  </span>
                </div>
              )}
              <button class="is-fullwidth" disabled={isButtonClicked}>Reset Password</button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
  // }
};

export default ResetPassword;
