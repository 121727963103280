import { h } from 'preact';
import { useState, useEffect } from "preact/hooks";
import {route} from 'preact-router';
import { Toast } from '../../../lib/toastr';
import CONSTANTS from '../../../lib/constants';
import { AppStore } from '../../../lib/store';
import http from 'fetch-bb';
// import linkState from 'linkstate';

// export default class changePassword extends Component {
const changePassword = (props) => {

  let [ otype, setOtype ] = useState('password');
  let [ ntype, setNtype ] = useState('password');
  let [ score, setScore ] = useState('null');
  let [ newpassword, setNewpassword ] = useState('');
  let [ confirmNewPassword, setConfirmNewPassword ] = useState('');
  let [ errorMessage, setErrorMessage ] = useState('');
  let [ successMessage, setSuccessMessage ] = useState('');
  let [ isButtonClicked, setIsButtonClicked ] = useState(false);

  function showHide(e) {
    e.preventDefault();
    // e.stopPropagation();
    // this.setState({
    //   otype: otype === 'text' ? 'password' : 'text'
    // });
    otype === 'text' ? setOtype('password') : setOtype('text');
  }

  function showHideN(e) {
    e.preventDefault();
    // e.stopPropagation();
    // this.setState({
    //   ntype: ntype === 'text' ? 'password' : 'text'
    // });
    ntype === 'text' ? setNtype('password') : setNtype('text');
  }

  function changePassword(e) {
    e.preventDefault();
    // this.setState({
    //   isResponseReceived:true,
    //   newpassword : e.target.newPass.value,
    //   confirmNewPassword : e.target.confirmPass.value,
    //   isButtonClicked: true
    // });
    setIsResponseReceived(true);
    setNewpassword(e.target.newPass.value);
    setConfirmNewPassword(e.target.confirmPass.value);
    setIsButtonClicked(true);

    let newpassword = e.target.newPass.value;
    let confirmNewPassword = e.target.confirmPass.value;
    // if (newpassword !== confirmNewPassword){
    //   this.setState({
    //     isResponseReceived:false,
    //     isButtonClicked: false
    //   });
    //   new Toast('Passwords do not match', Toast.TYPE_ERROR, Toast.TIME_LONG);
    // }
    http.put(`${CONSTANTS.API_URL}/api/admin/user/changepassword`,{
      newPassword: newpassword,
      confirmpassword: confirmNewPassword
    })
      .then(() => {
        new Toast('Password changed successfully, login again with new password', Toast.TYPE_DONE, Toast.TIME_LONG);
        // this.setState({errorMessage: ''});
        setErrorMessage('');
        logout();
      })
      .catch((HTTPException) => {
        if (HTTPException.statusCode === 409) {
          // this.setState({
          //   isResponseReceived:false,
          //   isButtonClicked: false
          // });
          setIsResponseReceived(false);
          setIsButtonClicked(false);
          new Toast('Passwords do not match', Toast.TYPE_ERROR, Toast.TIME_LONG);
          // this.setState({errorMessage: 'Passwords do not match', successMessage: ''});
          setErrorMessage('Passwords do not match');
          setSuccessMessage('');
          return '';
        }
        else if (HTTPException.statusCode === 400) {
          // this.setState({
          //   isResponseReceived:false,
          //   isButtonClicked: false
          // });
          setIsResponseReceived(false);
          setIsButtonClicked(false);
          new Toast('Passwords must contain At least 6 characters, including UPPERCASE, lowercase letters, '+
           'special characters and Digits', Toast.TYPE_ERROR, Toast.TIME_LONG);
          // this.setState({errorMessage: 'Passwords must contain At least 6 characters, including UPPERCASE, lowercase letters, '+
          //   'special characters and Digits', successMessage: ''});
          setErrorMessage('Passwords must contain At least 6 characters, including UPPERCASE, lowercase letters, special characters and Digits');
          setSuccessMessage('');
          return "";
        }
        // this.setState({
        //   isResponseReceived: false,
        //   isButtonClicked: false
        // });
        setIsResponseReceived(false);
        setIsButtonClicked(false);
      });
  }

  function logout() {
    http.post(`${CONSTANTS.API_URL}/api/admin/logout`)
      .then(() => {
        AppStore.removeAll();
        new Toast('Successfully logged out', Toast.TYPE_DONE, Toast.TIME_NORMAL);
        route(`/admin/login`);
      })
      .catch((HTTPException) => {
        console.error(HTTPException);
      });
  }

  function goBack() {
    return history.go(-2);
  }

  // componentWillMount() {
  //   this.state = {
  //     otype: 'password',
  //     ntype: 'password',
  //     score: 'null',
  //     isResponseReceived: false,
  //     isButtonClicked: false,
  //     newpassword : '',
  //     confirmNewPassword :'',
  //     errorMessage: '',
  //     successMessage: ''
  //   };
  // }

  // componentDidMount() {
  // }

  // render ({}, {}) {//newpassword, confirmNewPassword
  return (
    <section class="row row-center has-text-center auth-section verify-background">
      <div class="column  auth-center">
        <form class="box" onSubmit={() => changePassword()}>
          <div class="row">
            <div class="column">
              <h6 class="inline">Confirm Password</h6>
            </div>
          </div>
          <div class="row">
            <div class="column auth-form" style="position:relative;">
              <div class="row field">
                <input id="txtpassword" name="newPass" value={newpassword}
                  type={otype} placeholder="Enter New Password" disabled={isResponseReceived}
                  onInput={e => setNewpassword(e.target.value)} required/>
                <label for="txtpassword" class="has-text-left" style="font-size:0.8em">Enter New Password</label>
                <span onClick={() => showHide()} class="has-text-right"
                  style="position:absolute; cursor: pointer; font-size: 1.6em; right: 16px; top: 50px;">
                  <i class={otype === 'text' ? 'icon-eye' : 'icon-eye-disabled'}/>
                </span>
              </div>
              <div class="row field">
                <input id="confirmPass" name="confirmPass" type={ntype} placeholder="Confirm New Password"
                  value={confirmNewPassword} onInput={e => setConfirmNewPassword(e.target.value)} disabled={isResponseReceived} required/>
                <label for="confirmPass" class="has-text-left" style="font-size:0.8em">Confirm New Password</label>
                <span onClick={() => showHideN()} class="has-text-right"
                  style="position:absolute; cursor: pointer; font-size: 1.6em; right: 16px; top: 115px;">
                  <i class={ntype === 'text' ? 'icon-eye' : 'icon-eye-disabled'}/>
                </span>
              </div>
              {errorMessage && (
                <div id="error" class="error-color">
                  <span>
                    {errorMessage}

                  </span>
                </div>
              )}
              {successMessage && (
                <div>
                  <span>{successMessage}

                  </span>
                </div>
              )}
              <div class="row">
                <div class="column">
                  <button type="button" onClick={() => goBack()} id="confirmButton" class="is-fullwidth">Cancel</button>
                </div>
                <div class="column">
                  <button type="submit" disabled={isButtonClicked} id="confirmButton" class="is-fullwidth">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <p>  Choose a strong password and do not reuse it for other accounts..</p>
      </div>
    </section>
  );
  // }
};

export default changePassword;
