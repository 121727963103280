import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

const Dropdown = (props) => {
  return (
    <span class={`${props.masterClasses || ''}`} title={props.title || ''}>
      <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20">
        <path d="M10 12.458q-.167 0-.323-.062-.156-.063-.302-.208L5.604 8.417q-.25-.25-.229-.625.021-.375.25-.604.271-.271.625-.261.354.011.604.261L10 10.354l3.167-3.166q.25-.25.593-.25.344 0 .615.27.25.25.25.615t-.25.615l-3.75 3.75q-.146.145-.302.208-.156.062-.323.062Z"/>
      </svg>
    </span>
  );
};

export default Dropdown;
