import { h } from 'preact';
import { useState, useEffect } from "preact/hooks";
import { Modal, ModalBody, ModalFooter } from '../../../components/modal';
import CONSTANTS from '../../../lib/constants';
import http from 'fetch-bb';
import { Toast } from '../../../lib/toastr';
// import linkState from 'linkstate';
import { startLoader, stopLoader } from '../../../lib/utils';
import Pagination from '../../../components/pagination';
import { route } from 'preact-router';

// export default class Companies extends Component {
const Companies = (props) => {
  // componentWillMount() {
  //   this.state = {
  //     isAddCompanyModalOpen: false,
  //     company: {
  //       address: {}
  //     },
  //     companies: [],
  //     isEditCompany: false,
  //     modalTitle: 'Add Company',
  //     companyObj: {}  //Cloned object for edit
  //   };
  // }

  let [ company, setCompany ] = useState({address: {}});
  let [ companies, setCompanies ] = useState([]);
  let [ isEditCompany, setIsEditCompany ] = useState(false);
  let [ modalTitle, setModalTitle ] = useState('Add Company');
  let [ companyObj, setCompanyObj ] = useState({});
  let [ isAddCompanyModalOpen, setIsAddCompanyModalOpen ] = useState(false);
  let [ address, setAddress ] = useState({});
  let [ totalPages, setTotalPages] = useState(0);
  let [ currentPageNo, setCurrentPageNo ] = useState(1);

  async function getCompanies() {
    try {
      startLoader();
      let companies = await http.get(`${CONSTANTS.API_URL}/api/company`);
      stopLoader();
      // this.setState({companies});
      setCompanies(companies);
    }
    catch (HTTPException) {
      stopLoader();
      console.error(HTTPException);
      new Toast(HTTPException.message, Toast.TYPE_ERROR, Toast.TIME_LONG);
    }
  }

  async function createOrEditCompany(e) {
    e.preventDefault();
    let body = {
      displayName: e.target.displayName.value,
      name: e.target.name.value,
      shortName: e.target.shortName.value,
      line1: e.target.line1.value,
      line2: e.target.line2.value,
      city: e.target.city.value,
      state: e.target.state.value,
      pincode: e.target.pincode.value,
      status: e.target.status.value,
      contactName: e.target.contactName.value,
      contactPhone: e.target.contactPhone.value,
      contactEmail: e.target.contactEmail.value,
      subscriptionStatus: e.target.subscriptionStatus.value,
      pan: e.target.pan.value,
      gst: e.target.gst.value
    };
    try {
      startLoader();
      let url = isEditCompany ? `${CONSTANTS.API_URL}/api/company/${companyObj.uuid}` : `${CONSTANTS.API_URL}/api/company`;
      let method = isEditCompany ? 'put' : 'post';
      await http[method](url, body);
      toggleAddCompany('close');
      await getCompanies();
    } catch (HTTPException) {
      stopLoader();
      console.error(HTTPException);
      new Toast(HTTPException.message, Toast.TYPE_ERROR, Toast.TIME_LONG);
    }
  }

  // componentDidMount() {
  //   // if ( document.getElementById("gn-menu-nav").classList.contains('gn-open-all') ) {
  //   //   document.getElementById("main-body").classList.remove('margin-left-76');
  //   //   document.getElementById("main-body").classList.add('margin-left-266');
  //   // } else {
  //   //   document.getElementById("main-body").classList.remove('margin-left-266');
  //   //   document.getElementById("main-body").classList.add('margin-left-76');
  //   // }
  //   this.getCompanies();
  // }

  useEffect(() => {
    getCompanies();
  }, []);

  function toggleAddCompany(action, row) {
    if (action === 'add') {
      // this.setState({isAddCompanyModalOpen: !this.state.isAddCompanyModalOpen, isEditCompany: false, modalTitle: 'Add Company', companyObj: {address: {}}});
      setIsAddCompanyModalOpen(!isAddCompanyModalOpen);
      setIsEditCompany(false);
      setModalTitle('Add Company');
      setCompanyObj({address: {}});
    }
    if (action === 'edit') {
      // this.setState({isAddCompanyModalOpen: !this.state.isAddCompanyModalOpen, isEditCompany: true, modalTitle: 'Edit Company', companyObj: Object.assign({}, row)});
      setIsAddCompanyModalOpen(!isAddCompanyModalOpen);
      setIsEditCompany(true);
      setModalTitle('Edit Company');
      setCompanyObj(Object.assign({}, row));
    }
    if (action === 'close') {
      // this.setState({isAddCompanyModalOpen: !this.state.isAddCompanyModalOpen, isEditCompany: false, company: {address: {}}, companyObj: {address: {}}});
      setIsAddCompanyModalOpen(!isAddCompanyModalOpen);
      setIsEditCompany(false);
      setCompany({address: {}});
      setCompanyObj({address: {}});
    }
  }

  function goToDetails() {
    route('/company/1234');
  }

  // render({}, state) {

    return (
      <div>
        {/*<SideBar1 activeMenu={'/clients'}/>*/}
        <div id="main-body" class=" main outer-most-div margin-left-76">
          <section class="row" style="margin-bottom:5px">
            <div class="column p-0">
              <ul class="breadcrumbs">
                <li class="active">COMPANY ADMINISTRATION</li>
              </ul>
            </div>
          </section>
          <section class="row">
            <div class="column no-padding">
              <div class="box">
                <div class="row">
                  <div class="column has-text-right">
                    <button type="button" onClick={() => toggleAddCompany('add')}>Add Company</button>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div class="box">
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Short Name</th>
                  <th>Status</th>
                  <th>Contact Name</th>
                  <th>Contact Number</th>
                  <th>Subscription Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  companies.map((row) => (

                    <tr>
                      <td>{row.displayName}</td>
                      <td>{row.shortName}</td>
                      <td>{row.status}</td>
                      <td>{row.contactName}</td>
                      <td>{row.contactPhone}</td>
                      <td>{row.subscriptionStatus}</td>
                      <td>
                        <button type="button" onClick={() => toggleAddCompany('edit', row)}>Edit</button>
                        <button type="button" onClick={() => goToDetails()}>View Details</button>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
            <div class="has-text-right column no-padding pagination">
              <Pagination count={totalPages} currentPageNo={currentPageNo} />
            </div>
          </div>
          {
            isAddCompanyModalOpen && (
              <Modal title={modalTitle} modalSize="is-medium" onClose={() => toggleAddCompany('close')}>
                <form name="addOrEditCompany" onSubmit={() => createOrEditCompany()}>
                  <ModalBody>
                    <div class="row">
                      <div class="column">
                        <label style="margin-bottom:5px">Company Information</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="column">
                        <label>Name</label>
                        <input value={companyObj.name} type="text" onInput={e => setCompanyObj(existingValues => ({...existingValues, name: e.target.value}))}
                          required placeholder="Enter Name" name="name" />
                      </div>
                      <div class="column">
                        <label>Display name</label>
                        <input value={companyObj.displayName} type="text" onInput={e => setCompanyObj(existingValues => ({...existingValues, displayName: e.target.value}))}
                          required placeholder="Enter Display Name" name="displayName" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="column column-50 float-left">
                        <label>Short Name</label>
                        <input value={companyObj.shortName} type="text" onInput={e => setCompanyObj(existingValues => ({...existingValues, shortName: e.target.value}))}
                          required placeholder="Enter Short Name" name="shortName" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="column">
                        <label>Status</label>
                        <select required name="status" onInput={e => setCompanyObj(existingValues => ({...existingValues, status: e.target.value}))}>
                          <option value='active' selected={companyObj.status === 'active'}>Active</option>
                          <option value='disabled' selected={companyObj.status === 'disabled'}>Disable</option>
                          <option value='live' selected={companyObj.status === 'live'}>Live</option>
                          <option value='demo' selected={companyObj.status === 'demo'}>Demo</option>
                          <option value='suspended' selected={companyObj.status === 'suspended'}>Suspended</option>
                        </select>
                      </div>
                      <div class="column">
                        <label>Subscription Status</label>
                        <select name="subscriptionStatus" onInput={e => setCompanyObj(existingValues => ({...existingValues, subscriptionStatus: e.target.value}))}>
                          <option selected={companyObj.subscriptionStatus === 'active'} value='active'>Active</option>
                          <option selected={companyObj.subscriptionStatus === 'disabled'} value='disabled'>Disable</option>
                        </select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="column">
                        <label>PAN No.</label>
                        <input value={companyObj.pan} onInput={e => setCompanyObj(existingValues => ({...existingValues, pan: e.target.value}))} type="text"
                          placeholder="State" name="pan" />
                      </div>
                      <div class="column">
                        <label>GST No.</label>
                        <input value={companyObj.gst} onInput={e => setCompanyObj(existingValues => ({...existingValues, gst: e.target.value}))} type="text"
                          placeholder="State" name="gst" />
                      </div>
                    </div>
                    <hr/>
                    <div class="row">
                      <div class="column">
                        <label style="margin-bottom:5px">Address Information</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="column">
                        <label>Address Line 1</label>
                        <input value={companyObj.address.line1} onInput={e => setAddress(existingValues => ({...existingValues, line1: e.target.value}))} type="text" placeholder="Line 1" name="line1" />
                      </div>
                      <div class="column">
                        <label>Address Line 2</label>
                        <input value={companyObj.address.line2} onInput={e => setAddress(existingValues => ({...existingValues, line2: e.target.value}))} type="text" placeholder="Line 2" name="line2" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="column">
                        <label>Pincode</label>
                        <input value={companyObj.address.pincode} onInput={e => setAddress(existingValues => ({...existingValues, pincode: e.target.value}))} type="number"
                          placeholder="Pincode" name="pincode" />
                      </div>
                      <div class="column">
                        <label>City</label>
                        <input value={companyObj.address.city} onInput={e => setAddress(existingValues => ({...existingValues, city: e.target.value}))} type="text" placeholder="City" name="city" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="column column-50 float-left">
                        <label>State</label>
                        <input value={companyObj.address.state} onInput={e => setAddress(existingValues => ({...existingValues, state: e.target.value}))} type="text" placeholder="State" name="state" />
                      </div>
                    </div>
                    <hr/>
                    <div class="row">
                      <div class="column">
                        <label style="margin-bottom:5px">Contact Information</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="column">
                        <label>Name</label>
                        <input value={companyObj.contactName} onInput={e => setCompanyObj(existingValues => ({...existingValues, contactName: e.target.value}))} type="text" placeholder="Enter Name" name="contactName" />
                      </div>
                      <div class="column">
                        <label>Mobile</label>
                        <input value={companyObj.contactPhone} onInput={e => setCompanyObj(existingValues => ({...existingValues, contactPhone: e.target.value}))} type="text"
                          placeholder="Enter Mobile" name="contactPhone" maxlength="10" minlength="10"/>
                      </div>
                    </div>
                    <div class="row">
                      <div class="column column-50 float-left">
                        <label>Email</label>
                        <input value={companyObj.contactEmail} onInput={e => setCompanyObj(existingValues => ({...existingValues, contactEmail: e.target.value}))} type="email"
                          placeholder="Enter Email" name="contactEmail" />
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <button type="reset" class="button-clear" onClick={() => toggleAddCompany('close')}>Cancel</button>
                    <button>Save</button>
                  </ModalFooter>
                </form>
              </Modal>)
          }
        </div>
      </div>
    );
  // }
}

export default Companies;
